// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// @import 'bootstrap';

html,
body {
    height: 100%;
}

body {
    background-color: #000000f9;
    margin: 0;
}

::-webkit-scrollbar {
    width: 20px;
    @media (max-width: 700px) {
        display: none;
    }
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

#app {
    height: 100%;
}

#app::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.16;
    z-index: -1;
    background: url("./../media/cthuluminati-background.jpg") no-repeat center
        center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
}

//animation stuff
.header-enter {
    opacity: 0;
    top: -60px;
}

.header-enter-active {
    opacity: 1;
    top: 0px;
    transition: opacity 1500ms, top 750ms;
}

.header-exit {
    opacity: 1;
    top: 0px;
}

.header-exit-active {
    opacity: 0;
    top: -60px;
    transition: opacity 500ms, top 750ms;
}

.page-enter {
    opacity: 0;
}

.page-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}

.page-exit {
    opacity: 1;
}

.page-exit-active {
    opacity: 0;
    transition: opacity 1000ms;
}
